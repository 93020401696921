import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import BranchImage from "../images/branch-photo.png"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function Home() {
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>JustPax Collective</title>
          <link rel="canonical" href="http://justpaxcollective.com" />
      </Helmet>
      <Hero></Hero>
      <section class="section--how-we-help">
        <section className="flex-container wrapper">
          <div style={{marginRight:`3rem`}}>
            <h1>How we help</h1>
            <p>JustPax works with growing organizations and brands to provide strategic consulting, learner-centered programs, and coaching for diversity, equity, and inclusion (DEI) efforts. We foster partnerships built on trust, openness, and humility. Whether you’re a start-up or someone who’s been around the block a few times, we’re here to help you and your team thrive.</p>
          </div>
          <div className="how-we-help--image">
            <img src={BranchImage} alt="Olive branch" />
          </div>
        </section>
          
        <section className="flex-container wrapper how-we-help--services">
          <div class="how-we-help--column">
            <h2>Development</h2>
            <p>Through DEI training, coaching, and workshops, we focus on transforming hearts and minds and equipping people to pursue justice.</p>
            <Link to="/services/development/" className="button--secondary">Discover options</Link>
          </div>
          <div class="how-we-help--column">
            <h2>Consulting</h2>
            <p>This isn’t one-size-fits-all. We develop context-specific strategies and provide integration support for your team to thrive.</p>
            <Link to="/services/consulting/" className="button--secondary">Find out more</Link>
          </div>
          <div class="how-we-help--column">
            <h2>Learning Pods</h2>
            <p>We provide DEI learning pods to activate community-driven learning and applications.</p>
            <Link to="/services/learning-pods/" className="button--secondary">Sign me up</Link>
          </div>
        </section>
      </section>
      <section class="section--quotes">
        <div class="wrapper">
            <blockquote>“If you have come here to help me you are wasting your time, but if you have come because your liberation is bound up with mine, then let us work together.”</blockquote>
            <span class="section--quotes-author">Lilla Watson</span>
        </div>
      </section>
    </Layout>
  )
}
