import React from "react"
// import HeroDove from "../images/hero-dove.png"

export default function Hero({children}) {
    return ( 
        <section className="hero">
            <div>
                <h1>Mobilizing justice and peacemaking for the flourishing of our world.</h1>
            </div>
        </section>
    )
}